import React from "react";
import ApplicationStartSuccessPage from "@components/ApplicationStartSuccessPage/ApplicationStartSuccessPage";
import {} from "@components";
import { navigate } from "gatsby";

const AcademiaConfirmation: React.FunctionComponent = () => {
	return (
		<ApplicationStartSuccessPage
			image="application_confirmation"
			header="Thank you!"
			text="Your application to Genemod’s Academia Program has been received. Our team will review your application carefully and contact you soon!"
			buttonText="Back to home page"
			onClick={() => navigate("/")}
		/>
	);
};

export default AcademiaConfirmation;
